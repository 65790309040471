import "core-js/modules/es.array.join.js";
import { CHAR } from '@/config/common';
function insres(value) {
  if (_.isUndefined(value) || _.isNull(value)) {
    return _.join(_.times(2, _.constant(CHAR.DASH)), CHAR.NUL);
  } else {
    return _.isNumber(value) ? String(value) : value;
  }
}
var directiveIns = function directiveIns(app) {
  app.directive('ins', {
    mounted: function mounted(el, binding) {
      el.textContent = insres(binding.value);
    },
    updated: function updated(el, binding) {
      el.textContent = insres(binding.value);
    }
  });
};
export default directiveIns;