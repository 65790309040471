import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "cus-page-header"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: _ctx.title,
    "left-arrow": "",
    onClickLeft: _ctx.toBack
  }, {
    left: _withCtx(function () {
      return [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "24",
        color: "rgba(51, 51, 51, 1)"
      })];
    }),
    _: 1
  }, 8, ["title", "onClickLeft"])]);
}