import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  var _component_van_button = _resolveComponent("van-button");
  var _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: _ctx.locale
  }, {
    default: _withCtx(function () {
      return [_ctx.statusBarHeight ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "status-bar",
        style: _normalizeStyle({
          height: _ctx.statusBarHeight + 'px'
        })
      }, null, 4)) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [_createVNode(_Transition, null, {
            default: _withCtx(function () {
              return [(_openBlock(), _createBlock(_KeepAlive, {
                include: ['ApplyRequisition', 'DeviceInfo', 'PatrolGuard', 'DeviceAlarm', 'PatrolScheme']
              }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))];
            }),
            _: 2
          }, 1024)];
        }),
        _: 1
      }), _ctx.isHome ? (_openBlock(), _createBlock(_component_van_button, {
        key: 1,
        round: "",
        plain: "",
        hairline: "",
        type: "primary",
        onClick: _ctx.goHome,
        icon: "wap-home-o",
        class: "home-button"
      }, null, 8, ["onClick"])) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["locale"]);
}