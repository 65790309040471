export type Code = 0 | 1 | 200;

export enum Status {
  success = '1',
  failure = '0',
  tokenInvalid = '2001'
}

export interface Response<T = any> {
  data: T;
  code: Code;
  info: string;
  status: Status;
  msg: null | string;
}

export interface PageParams {
  pageSize: number;
  pageNumber: number;
}

export interface PageRes {
  totalRow: number; // 总条数
  pageSize: number; // 一页多少条
  totalPage: number; // 总页数
  pageNumber: number; // 当前页
}

export interface ListData<T> {
  list: T[];
}
