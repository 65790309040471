/* unplugin-vue-components disabled */import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
// import router from './router';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import { ROUTE_NAME, DEVELOPMENT } from '@/config/common';
export default defineComponent({
  components: {
    ElConfigProvider: ElConfigProvider
  },
  setup: function setup() {
    var statusBarHeight = 0;
    var router = useRouter();
    var dateType = ref(0);
    provide('defaultDateType', readonly(dateType));
    var defaultDate = ref('');
    provide('defaultDate', readonly(defaultDate));
    var isHome = computed(function () {
      if (_.eq(process.env.NODE_ENV, DEVELOPMENT)) {
        return !_.eq(router.currentRoute.value.name, ROUTE_NAME.HOME);
      }
      return false;
    });
    function getStatusBarHeight() {
      if (window.visualViewport) {
        statusBarHeight = window.visualViewport.offsetTop;
      } else if (window.screen && window.matchMedia('(display-cutout: constant)').matches) {
        var devicePixelRatio = window.devicePixelRatio;
        statusBarHeight = Math.round(24 * devicePixelRatio); // 这里的24需要根据设备实际情况进行调整
      }
      return statusBarHeight;
    }
    function goHome() {
      router.replace({
        name: ROUTE_NAME.HOME
      });
    }
    getStatusBarHeight();
    return {
      goHome: goHome,
      isHome: isHome,
      locale: zhCn,
      statusBarHeight: statusBarHeight
    };
  }
});