import { Module } from 'vuex';
import { IRootState } from '../type';
const deviceAlarmModule: Module<any, IRootState> = {
  namespaced: true,
  state: () => {
    return {
      scrollTop: 0
    };
  },
  getters: {
    getScrollTop(state) {
      return state.scrollTop;
    }
  },
  mutations: {
    setScrollTop(state, scrollTop) {
      state.scrollTop = scrollTop;
    }
  }
};
export default deviceAlarmModule;
