/* unplugin-vue-components disabled */export default defineComponent({
  name: 'Nothing',
  props: {
    text: {
      type: String,
      default: '暂无数据'
    }
  },
  setup: function setup() {
    return {};
  }
});