import { ROUTE_NAME } from '@/config/common';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: ROUTE_NAME.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Home/Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/salesAggregateDailyReport',
    name: 'SalesAggregateDailyReport',
    meta: {
      title: '销售-骨料报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportAggregate/ReportAggregate.vue'
      )
  },
  {
    path: '/aggregateSalesPrice',
    name: 'SalePrice',
    meta: {
      title: '销售-销售价格'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/SalePrice/SalePrice.vue'
      )
  },
  {
    path: '/saleMortarDailyReport',
    name: 'SaleMortarDailyReport',
    meta: {
      title: '销售-砂浆报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportMortar/ReportMortar.vue'
      )
  },
  {
    path: '/saleShangTongDailyReport',
    name: 'SaleShangTongDailyReport',
    meta: {
      title: '销售-商砼报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportShangTong/ReportShangTong.vue'
      )
  },
  {
    path: '/planDetail',
    name: 'PlanDetail',
    meta: {
      title: '销售-商砼计划详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportShangTong/PlanDetail.vue'
      )
  },
  {
    path: '/saleReportDetail',
    name: 'SaleReportDetail',
    meta: {
      title: '销售-明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportDetail/ReportDetail.vue'
      )
  },
  {
    path: '/purchaseShangTongDailyReport',
    name: 'purchaseShangTongDailyReport',
    meta: {
      title: '采购-商砼报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportShangTong/ReportShangTong.vue'
      )
  },
  {
    path: '/purchaseMortarDailyReport',
    name: 'PurchaseMortarDailyReport',
    meta: {
      title: '采购-砂浆报表'
    },
    props: {
      type: 0
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportMortar/ReportMortar.vue'
      )
  },
  {
    path: '/purchaseAggregateDailyReport',
    name: 'PurchaseAggregateDailyReport',
    meta: {
      title: '采购-骨料原石报表'
    },
    props: {
      type: 0
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportAggregate/ReportAggregate.vue'
      )
  },
  {
    path: '/purchaseSpareParts',
    name: 'PurchaseSpareParts',
    meta: {
      title: '采购-备品备件'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/SpareParts/SpareParts.vue'
      )
  },
  {
    path: '/sparePartsDetail',
    name: 'SpartPartsDetail',
    meta: {
      title: '采购-备品备件详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/SpareParts/Detail.vue'
      )
  },
  {
    path: '/purchaseReportDetail',
    name: 'PurchaseReportDetail',
    meta: {
      title: '采购-明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportDetail/ReportDetail.vue'
      )
  },
  {
    path: '/preform',
    meta: {
      title: '盘料模块'
    },
    redirect: '/preform/countComprehensive', // 盘料-综合统计
    // redirect: '/preform/ratioDetails', // 盘料-占比详情
    // redirect: '/preform/record', // 盘料-统计记录
    children: [
      {
        path: 'countComprehensive',
        name: ROUTE_NAME.PREFORM_COUNT_COMPREHENSIVE,
        component: () =>
          import(
            /* webpackChunkName: "preform" */ '../views/Preform/CountComprehensive/Index.vue'
          ),
        meta: {
          title: '盘料-综合统计'
        }
      },
      {
        path: 'ratioDetails',
        name: ROUTE_NAME.PREFORM_COUNT_RATIO_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "preform" */ '../views/Preform/CountComprehensive/RatioDetails.vue'
          ),
        meta: {
          title: '盘料-占比详情'
        }
      },
      {
        path: 'record',
        name: ROUTE_NAME.PREFORM_COUNT_RECORD,
        component: () =>
          import(
            /* webpackChunkName: "preform" */ '../views/Preform/CountComprehensive/Record.vue'
          ),
        meta: {
          title: '盘料-统计记录'
        }
      }
    ]
  },
  {
    path: '/prodComprehensiveDaily',
    name: ROUTE_NAME.PROD_COMPREHENSIVE_DAILY,
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/ComprehensiveDaily/ComprehensiveDaily.vue'
      ),
    meta: {
      title: '运营-综合日报'
    }
  },
  {
    path: '/blueSkyBudget',
    name: ROUTE_NAME.BUDGET_REPORT,
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/BudgetReport/BudgetReport.vue'
      ),
    meta: {
      title: '运营-预算分析'
    }
  },
  {
    path: '/energyManagement',
    name: ROUTE_NAME.ENERGY_MANAGEMENT,
    meta: {
      title: '运营-能源管理'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/EnergyManagement/EnergyManagement.vue'
      )
  },
  {
    path: '/energyDetail',
    name: ROUTE_NAME.ENERGY_DETAIL,
    meta: {
      title: '运营-能源消耗'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/EnergyManagement/EnergyDetail.vue'
      )
  },
  {
    path: '/dailyInspection',
    name: ROUTE_NAME.DAILY_INSPECTION,
    meta: {
      title: '运营-设备日检'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/DailyInspection/DailyInspection.vue'
      )
  },
  {
    path: '/dailyInspectionDetail',
    name: ROUTE_NAME.DAILY_INSPECTION_DETAIL,
    meta: {
      title: '运营-设备日检-详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/DailyInspection/DailyInspectionDetail.vue'
      )
  },
  {
    path: '/attendanceAnalysis',
    name: ROUTE_NAME.ATTENDANCE_ANALYSIS,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Work/AttendanceAnalysis/AttendanceAnalysis.vue'
      ),
    meta: {
      title: '办公-考勤分析'
    }
  },
  {
    path: '/absentPersonnel',
    name: ROUTE_NAME.ABSENT_PERSONNEL,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Work/AttendanceAnalysis/AbsentPersonnel.vue'
      ),
    meta: {
      title: '办公-未出勤人员'
    }
  },
  {
    path: '/communication',
    name: ROUTE_NAME.COMMUNICATION,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/Index/Index.vue'
      ),
    meta: {
      title: '办公-通讯录'
    }
  },
  {
    path: '/PersonalDetail',
    name: ROUTE_NAME.PERSONAL_DETAIL,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/PersonalDetail/PersonalDetail.vue'
      ),
    meta: {
      title: '办公-个人详情'
    }
  },
  {
    path: '/myDepartment',
    name: ROUTE_NAME.MY_DEPARTMENT,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/MyDepartment/MyDepartment.vue'
      ),
    meta: {
      title: '办公-我的部门'
    }
  },
  {
    path: '/externalContacts',
    name: ROUTE_NAME.EXTERNAL_CONTACTS,
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/ExternalContacts/ExternalContacts.vue'
      ),
    meta: {
      title: '办公-外部联系人'
    }
  },
  {
    path: '/productionLog',
    name: ROUTE_NAME.PRO_REPORT_AGGREGATE,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportAggregate/ProReportAggregate.vue'
      ),
    meta: {
      title: '生产报表'
    }
  },
  {
    path: '/yieldCorrection',
    name: ROUTE_NAME.YIELD_CORRECTION,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/YieldCorrection/YieldCorrection.vue'
      ),
    meta: {
      title: '产量修正'
    }
  },
  {
    path: '/collectionCorrection',
    name: ROUTE_NAME.COLLECTION_CORRECTION,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Operate/ComprehensiveDaily/collectionCorrection.vue'
      ),
    meta: {
      title: '收款修正'
    }
  },
  {
    path: '/modifyRecords',
    name: ROUTE_NAME.MODIFY_RECORDS,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/YieldCorrection/ModifyRecords.vue'
      ),
    meta: {
      title: '修改记录'
    }
  },
  {
    path: '/shutdownReason',
    name: ROUTE_NAME.SHUTDOWN_REASON,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportAggregate/ShutdownReason.vue'
      ),
    meta: {
      title: '停机原因'
    }
  },
  {
    path: '/stockAggregateLevel',
    name: ROUTE_NAME.STOCK_AGGREGATE_LEVEL,
    meta: {
      title: '成品料位'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/AggregateLevel/AggregateLevel.vue'
      )
  },
  {
    path: '/stockMonitor',
    name: ROUTE_NAME.STOCK_MONITOR,
    meta: {
      title: '生产-监控'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/Monitor/Monitor.vue'
      )
  },
  {
    path: '/proReportShangTong',
    name: ROUTE_NAME.PRO_REPORT_SHANG_TONG,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportShangTong/ProReportShangTong.vue'
      ),
    meta: {
      title: '生产-商砼报表'
    }
  },
  {
    path: '/proReportMortar',
    name: ROUTE_NAME.PRO_REPORT_MORTAR,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportMortar/ProReportMortar.vue'
      ),
    meta: {
      title: '生产-砂浆报表'
    }
  },
  {
    path: '/stoneSaleRank',
    name: ROUTE_NAME.STONE_SALE_RANK,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/StoneSaleRank/StoneSaleRank.vue'
      ),
    meta: {
      title: '物料销售排行'
    }
  },
  {
    path: '/proEquipmentLive',
    name: ROUTE_NAME.PRO_EQUIPMENT_LIVE,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/EquipmentLive.vue'
      ),
    meta: {
      title: '生产-设备状态'
    },
    redirect: '/proEquipmentLive/list',
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.PRO_EQUIPMENT_LIVE_LIST,
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/List.vue'
          ),
        meta: {
          title: '生产-设备状态'
        }
      },
      {
        path: 'detail',
        name: ROUTE_NAME.PRO_EQUIPMENT_LIVE_DETAIL,
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Detail.vue'
          ),
        meta: {
          title: '设备状态详情'
        }
      }
    ]
  },
  {
    path: '/drawings',
    name: 'Drawings',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Drawings.vue'
      ),
    meta: {
      title: '电气图纸'
    }
  },
  {
    path: '/MaintenanceList',
    name: 'MaintenanceList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Maintenance.vue'
      ),
    meta: {
      title: '设备保养记录'
    }
  },
  {
    path: '/RepairList',
    name: 'RepairList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Repair.vue'
      ),
    meta: {
      title: '设备维修记录'
    }
  },
  {
    path: '/PointRepairList',
    name: 'PointRepairList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/PointRepair.vue'
      ),
    meta: {
      title: '点检记录'
    }
  },
  {
    path: '/usings',
    name: 'Usings',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Usings.vue'
      ),
    meta: {
      title: '使用说明'
    }
  },
  {
    path: '/warehouseSpareParts',
    name: 'WarehouseSpareParts',
    meta: {
      title: '仓库-实时库存'
    },
    component: () =>
      import(
        /* webpackChunkName: "storeHouse" */ '../views/Warehouse/SpareParts/SpareParts.vue'
      )
  },
  {
    path: '/warehouseRequisition',
    name: 'WarehouseRequisition',
    meta: {
      title: '仓库-领用情况'
    },
    component: () =>
      import(
        /* webpackChunkName: "storeHouse" */ '../views/Warehouse/Requisition/Requisition.vue'
      )
  },
  // {
  //   path: '/stockAggregate',
  //   name: 'StockAggregate',
  //   meta: {
  //     title: '库存-骨料成品'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/Aggregate/Aggregate.vue'
  //     )
  // },
  // {
  //   path: '/stockShangTong',
  //   name: 'StockShangTong',
  //   meta: {
  //     title: '库存-商砼原料'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/ShangTong/ShangTong.vue'
  //     )
  // },
  // {
  //   path: '/stockMortar',
  //   name: 'StockMortar',
  //   meta: {
  //     title: '库存-砂浆原料'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/Mortar/Mortar.vue'
  //     )
  // },
  // {
  //   path: '/stockShangTongLevel',
  //   name: 'StockShangTongLevel',
  //   meta: {
  //     title: '库存-商砼料位'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/ShangTongLevel/ShangTongLevel.vue'
  //     )
  // },
  // {
  //   path: '/stockMortarLevel',
  //   name: 'StockMortarLevel',
  //   meta: {
  //     title: '库存-砂浆料位'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/MortarLevel/MortarLevel.vue'
  //     )
  // },
  {
    path: '/productionData',
    name: 'ProductionData',
    meta: {
      title: '管家报表-生产数据'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/ProductionData/ProductionData.vue'
      )
  },

  {
    path: '/productAndSale',
    name: 'ProductAndSale',
    meta: {
      title: '产销对比'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ProductAndSale/Index.vue'
      )
  },
  {
    path: '/customerAnalysisData',
    name: 'CustomerAnalysisData',
    meta: {
      title: '客户经营分析'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ProductAndSale/Count.vue'
      )
  },
  {
    path: '/applyRequisition',
    name: 'ApplyRequisition',
    meta: {
      title: '生产领用',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/ApplyRequisition/Index.vue'
      )
  },
  {
    path: '/selectMaterial',
    name: 'SelectMaterial',
    meta: {
      title: '选择物料'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectMaterial/Index.vue'
      )
  },
  {
    path: '/selectWarehouse',
    name: 'SelectWarehouse',
    meta: {
      title: '选择仓库'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectWarehouse/Index.vue'
      )
  },
  {
    path: '/selectStaff',
    name: 'SelectStaff',
    meta: {
      title: '选择员工'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectStaff/Index.vue'
      )
  },
  {
    path: '/requisitionList',
    name: 'RequisitionList',
    meta: {
      title: '生产领用明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/RequisitionList/Index.vue'
      )
  },
  {
    path: '/requisitionMaterialList',
    name: 'RequisitionMaterialList',
    meta: {
      title: '领用物料'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/RequisitionMaterialList/Index.vue'
      )
  },
  {
    path: '/materialPositionInfo',
    name: 'MaterialPositionInfo',
    meta: {
      title: '货位信息'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/MaterialPositionInfo/Index.vue'
      )
  },
  {
    path: '/rechargeConsumptionCount',
    name: 'RechargeConsumptionCount',
    meta: {
      title: '充值消费-统计'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/Sale/Count/Index.vue'
      )
  },
  {
    path: '/financeBudget',
    name: 'FinanceBudget',
    meta: {
      title: '预算录入'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/Finance/Budget/Index.vue'
      )
  },
  {
    path: '/patrolScheme',
    name: 'PatrolScheme',
    meta: {
      title: '巡检计划',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/index.vue'
      )
  },
  {
    path: '/addPatrolScheme',
    name: 'AddPatrolScheme',
    meta: {
      title: '添加巡检计划'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/addPatrolScheme.vue'
      )
  },

  {
    path: '/patrolScheme/detail',
    name: 'PatrolSchemeDetail',
    meta: {
      title: '巡检详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/Detail.vue'
      )
  },
  {
    path: '/patrolGuard',
    name: 'PatrolGuard',
    meta: {
      title: '巡保任务'
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/PatrolGuard/index.vue')
  },
  {
    path: '/addPatrolGuard',
    name: 'AddPatrolGuard',
    meta: {
      title: '添加保养内容'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/addPatrolGuard.vue'
      )
  },
  {
    path: '/patrolGuard/detail',
    name: 'PatrolGuardDetail',
    meta: {
      title: '详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/Detail.vue'
      )
  },
  {
    path: '/patrolGuard/complete',
    name: 'PatrolGuardComplete',
    meta: {
      title: '添加保养内容'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/Complete.vue'
      )
  },
  {
    path: '/deviceInfo',
    name: 'DeviceInfo',
    meta: {
      title: '设备列表',
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceInfo/index.vue')
  },
  {
    path: '/deviceInfo/detail',
    name: 'DeviceInfoDetail',
    meta: {
      title: '设备详情'
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceInfo/Detail.vue')
  },
  {
    path: '/toPatrolScheme/detail',
    name: 'ToPatrolSchemeDetail',
    meta: {
      title: '巡检任务详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/fromDeviceInfo.vue'
      )
  },
  {
    path: '/toPatrolGuard/detail',
    name: 'ToPatrolGuardDetail',
    meta: {
      title: '保养任务详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/fromDeviceInfo.vue'
      )
  },
  {
    path: '/deviceAlarm',
    name: 'DeviceAlarm',
    meta: {
      title: '设备告警',
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceAlarm/index.vue')
  },
  {
    path: '/deviceAlarm/detail',
    name: 'DeviceAlarmDetail',
    meta: {
      title: '设备告警详情',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/Detail.vue'
      )
  },
  {
    path: '/deviceAlarm/addAlarmRule',
    name: 'AddAlarmRule',
    meta: {
      title: '告警规则'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/AddAlarmRule.vue'
      )
  },
  {
    path: '/deviceAlarm/handleAlarm',
    name: 'HandleAlarm',
    meta: {
      title: '处理告警'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/HandleAlarm.vue'
      )
  },
  {
    path: '/taskHistory',
    name: 'TaskHistory',
    meta: {
      title: '任务历史'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceInfo/history.vue'
      )
  },
  {
    path: '/vibrationEquipment',
    name: 'VibrationEquipment',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/VibrationEquipment/index.vue'
      ),
    meta: {
      title: '厂区设备'
    },
    redirect: '/vibrationEquipment/list',
    children: [
      {
        path: 'list',
        name: 'VibrationEquipmentList',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/VibrationEquipment/List.vue'
          ),
        meta: {
          title: '厂区设备'
        }
      },
      {
        path: 'curve',
        name: 'Curve',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/VibrationEquipment/Detail.vue'
          ),
        meta: {
          title: '砂机震动与电流曲线'
        }
      }
    ]
  }
];
