import { EDateType } from '@/interface';
import { PageParams } from '@/interface/api/common';

export const ROUTE_NAME = {
  HOME: 'Home',
  PROD_COMPREHENSIVE_DAILY: 'ProdComprehensiveDaily',
  BUDGET_REPORT: 'BudgetReport',
  ENERGY_MANAGEMENT: 'EnergyManagement',
  ENERGY_DETAIL: 'EnergyDetail',
  DAILY_INSPECTION: 'DailyInspection',
  DAILY_INSPECTION_DETAIL: 'DailyInspectionDetail',
  ATTENDANCE_ANALYSIS: 'AttendanceAnalysis',
  ABSENT_PERSONNEL: 'AbsentPersonnel',
  COMMUNICATION: 'Communication',
  PERSONAL_DETAIL: 'PersonalDetail',
  MY_DEPARTMENT: 'MyDepartment',
  EXTERNAL_CONTACTS: 'ExternalContacts',
  PRO_REPORT_AGGREGATE: 'ProReportAggregate',
  YIELD_CORRECTION: 'YieldCorrection',
  COLLECTION_CORRECTION: 'CollectionCorrection',
  MODIFY_RECORDS: 'ModifyRecords',
  SHUTDOWN_REASON: 'ShutdownReason',
  STOCK_AGGREGATE_LEVEL: 'StockAggregateLevel',
  STOCK_MONITOR: 'StockMonitor',
  PRO_REPORT_SHANG_TONG: 'ProReportShangTong',
  PRO_REPORT_MORTAR: 'ProReportMortar',
  STONE_SALE_RANK: 'StoneSaleRank',
  PRO_EQUIPMENT_LIVE: 'ProEquipmentLive',
  PRO_EQUIPMENT_LIVE_LIST: 'ProEquipmentLiveList',
  PRO_EQUIPMENT_LIVE_DETAIL: 'ProEquipmentLiveDetail',
  // 盘料
  PREFORM_COUNT_COMPREHENSIVE: 'PreformCountComprehensive',
  PREFORM_COUNT_RATIO_DETAILS: 'PreformCountRatioDetails',
  PREFORM_COUNT_RECORD: 'PreformCountRecord'
};

export const TIME_OUT = 20000;
export const DEVELOPMENT = 'development';

export const DAYJS_FORMAT = {
  YYYY: 'YYYY',
  HHMM: 'HH:mm',
  YYYY_CN: 'YYYY年',
  HHMMSS: 'HH:mm:ss',
  MMDD_SLASH: 'MM/DD',
  YYYYMM_SLASH: 'YYYY/MM',
  MMDD_HHMM: 'MM-DD HH:mm',
  YYYYMMDD_SLASH: 'YYYY/MM/DD',
  YYYYMMDD_HHMMSS: 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDD_HHMM_SLASH: 'YYYY/MM/DD HH:mm',
  YYYYMMDD_HHMMSS_SLASH: 'YYYY/MM/DD HH:mm:ss'
};

export const DAYJS_LOCALE_NAME = {
  ZHCN: 'zh-cn'
};
export const CHAR = {
  DASH: '-',
  PERCENT: '%',
  TILDE: '~',
  COLON_EN: ':',
  COLON_CN: '：',
  NUL: String(),
  ASTERISK: '*',
  COMMA_EN: ',',
  VERTICAL_BAR: '|',
  PARENTHESIS_L: '(',
  PARENTHESIS_R: ')',
  SINGLE_QUOTES: "'",
  SPACE: String().padStart(1),
  SLASH_FORWARD: '/', // 正
  SLASH_BACK: '\\', // 反
  QUESTION_MARK_EN: '?',
  QUESTION_MARK_CN: '？'
};

export const DATE_TAB_MAP = {
  [EDateType.day]: '日',
  [EDateType.week]: '周',
  [EDateType.month]: '月',
  [EDateType.year]: '年',
  [EDateType.custom]: '自定义'
};

export const DATA_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  UNDEFINED: 'undefined',
  NULL: 'null',
  SYMBOL: 'symbol',
  OBJECT: 'object',
  ARRAY: 'array',
  FUNCTION: 'function',
  DATE: 'date',
  REGEXP: 'regexp'
};

export const DEFAULT_PAGE: PageParams = {
  pageSize: 10,
  pageNumber: 1
};
