/* unplugin-vue-components disabled */import { useToBack } from '@/hooks/fn';
export default defineComponent({
  name: 'c-page-header',
  components: {},
  props: {},
  setup: function setup() {
    var toBack = useToBack();
    var route = useRoute();
    var title = route.meta.title;
    return {
      toBack: toBack,
      title: title
    };
  }
});