/* unplugin-vue-components disabled */export default defineComponent({
  name: 'CTypeTab',
  emits: ['confirm-type'],
  props: {
    backgroundColor: {
      type: String,
      default: '#ffffff'
    },
    typeTabs: {
      type: Array,
      default: function _default() {
        return ['日', '周', '月', '年', '自定义'];
      }
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var curType = ref(0);
    var typeTabClick = function typeTabClick(val) {
      curType.value = val;
      emit('confirm-type', val);
    };
    var defaultDateType = inject('defaultDateType');
    if (defaultDateType !== null && defaultDateType !== void 0 && defaultDateType.value) {
      curType.value = defaultDateType.value;
    }
    return {
      curType: curType,
      typeTabClick: typeTabClick
    };
  }
});