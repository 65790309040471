import { Module } from 'vuex';
import { IRootState } from '../type';
const patrolGuardModule: Module<any, IRootState> = {
  namespaced: true,
  state: () => {
    return {
      list: [],
      scrollTop: 0
    };
  },
  getters: {
    getList(state) {
      return state.list;
    },
    getScrollTop(state) {
      return state.scrollTop;
    }
  },
  mutations: {
    setList(state, list: any[]) {
      // 这里的 `state` 对象是模块的局部状态
      state.list = list || [];
    },
    setScrollTop(state, scrollTop) {
      state.scrollTop = scrollTop;
    }
  }
};
export default patrolGuardModule;
