import type { App } from 'vue';
import parseTime from '../hooks/parseTime';
import { DAYJS_FORMAT } from '@/config/common';
import { formatUtcString } from '@/utils/data-format';
export default function registerProperties(app: App): void {
  app.config.globalProperties.$filter = {
    formatUtcTime(utcString: string, format = DAYJS_FORMAT.YYYYMMDD_HHMMSS) {
      return formatUtcString(utcString, format);
    }
  };
  app.config.globalProperties.$emptyStringFormat = (
    val: string | number | null
  ) => {
    return val || val == '0' ? val : '--';
  };
  app.config.globalProperties.$parseTime = parseTime;
}
