import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1515a3c8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "c-button"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("button", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createTextVNode("按钮")];
  }, true)]);
}