/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'CColumn',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var isWarn = computed(function () {
      return Number(props.item.ratio) > 90;
    });
    return {
      isWarn: isWarn
    };
  }
});