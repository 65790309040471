import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { routes } from './routes';
import { namingTitle } from '@/utils';
import localCache from '@/utils/localCache';
import { ROUTE_NAME } from '@/config/common';
import { createRouter, createWebHistory } from 'vue-router';
var router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
});
router.afterEach(function (to, from) {
  console.log('从=>', from.path);
  console.log('到=>', to.path);
  document.title = namingTitle(to.meta.title);
  if (_.includes([ROUTE_NAME.HOME], to.name)) return;
  localCache.setItem('lastAccessRoute', to.name);
});
export default router;