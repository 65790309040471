import "core-js/modules/es.array.push.js";
import router from '@/router';
import { DAYJS_FORMAT } from '@/config/common';
//路由跳转
export var useToPage = function useToPage() {
  return function (path) {
    return router.push(path);
  };
};
//退出
export var useToBack = function useToBack() {
  return function () {
    return router.back();
  };
};
//日期格式化
export var useDateFormat = function useDateFormat() {
  return function () {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
    var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DAYJS_FORMAT.YYYYMMDD_HHMMSS;
    return dayjs(date).format(rule);
  };
};
export var useEmptyStringFormat = function useEmptyStringFormat() {
  var curInstance = getCurrentInstance();
  var emptyStringFormat = curInstance === null || curInstance === void 0 ? void 0 : curInstance.appContext.config.globalProperties.$emptyStringFormat;
  return emptyStringFormat;
};
//获取当前日期为当前的第多少周
export var getYearWeek = function getYearWeek(endDate) {
  var startDate = new Date(endDate.getFullYear(), 0, 1);
  var endWeek = endDate.getDay();
  if (endWeek == 0) endWeek = 7;
  var startWeek = startDate.getDay();
  if (startWeek == 0) startWeek = 7;
  var millisDiff = endDate.getTime() - startDate.getTime();
  var dayDiff = Math.floor((millisDiff + (startWeek - endWeek) * (24 * 60 * 60 * 1000)) / 86400000);
  return endDate.getFullYear() + '年/' + (Math.ceil(dayDiff / 7) + 1) + '周';
};