import _typeof from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import { CHAR } from '@/config/common';
export function namingTitle(title) {
  var NUL = String();
  var DELIMS = _.pad(CHAR.VERTICAL_BAR, 3);
  return _.join([title, DELIMS, process.env.VUE_APP_TITLE], NUL);
}
export function proxyPrefix(name) {
  return CHAR.SLASH_FORWARD.concat(name !== null && name !== void 0 ? name : String());
}
export function dataType(data) {
  var type = _typeof(data);
  if (type !== _typeof(null)) {
    return type;
  }
  var raw = Object.prototype.toString.call(data);
  return raw.match(/(?<=\s)\w+/)[0].toLowerCase();
}