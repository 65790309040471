import { createStore, useStore as useVuexStore } from 'vuex';
import { IRootState, IStoreType } from './type';
import production from './production';
import patrolScheme from './patrolScheme';
import patrolGuard from './patrolGuard';
import deviceInfo from './deviceInfo';
import deviceAlarm from './deviceAlarm';
import vibrationEquipment from './vibrationEquipment';

export default createStore<IRootState>({
  state: () => {
    return {};
  },
  mutations: {},
  actions: {},
  modules: {
    production,
    patrolScheme,
    patrolGuard,
    deviceInfo,
    deviceAlarm,
    vibrationEquipment
  }
});

export function useStore(): any {
  return useVuexStore<IStoreType>();
}
