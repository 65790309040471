import type { App } from 'vue';
import 'vant/lib/index.css'; // 全局引入样式
import {
  Toast,
  Button,
  Calendar,
  Icon,
  NavBar,
  Image as VanImage,
  Grid,
  GridItem,
  DatePicker,
  TimePicker,
  PickerGroup,
  Popup,
  Field,
  Form,
  CellGroup,
  Collapse,
  CollapseItem,
  Col,
  Row,
  Picker as VanPicker,
  List,
  Tab,
  Tabs,
  IndexBar,
  Cell,
  IndexAnchor,
  Search,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stepper,
  Progress,
  PullRefresh,
  Tag,
  Uploader,
  Empty,
  Step,
  Steps,
  Loading,
  Overlay,
  Highlight,
  Sticky
} from 'vant';

export default function registerVant(app: App): void {
  const components = [
    Toast,
    Button,
    NavBar,
    Icon,
    Calendar,
    VanImage,
    Grid,
    GridItem,
    DatePicker,
    TimePicker,
    PickerGroup,
    Popup,
    Field,
    Form,
    CellGroup,
    Col,
    Row,
    VanPicker,
    List,
    Tabs,
    Tab,
    IndexBar,
    IndexAnchor,
    Cell,
    Search,
    Collapse,
    CollapseItem,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
    Stepper,
    Progress,
    Tag,
    PullRefresh,
    Uploader,
    Empty,
    Step,
    Steps,
    Loading,
    Overlay,
    Highlight,
    Sticky
  ];
  for (const component of components) {
    if (typeof component.name === 'undefined') return;
    app.component(component.name, component);
  }
}
