/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(weekYear);
export default defineComponent({
  name: 'CDateHeader',
  props: {
    type: {
      type: Number,
      default: 1 //0日报  1周  2月
    },
    title: {
      type: String,
      default: '报表周期'
    },
    disabledDate: {
      type: String,
      default: 'disabledFuture'
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['confirm-date'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    // const formatDate = (date: Date) => {
    //   return dayjs(date).format('YYYY/MM/DD');
    // };
    var date = ref(new Date());
    var defaultDate = inject('defaultDate');
    var confirmDate = function confirmDate(value) {
      if (props.type === 1) {
        // emit('confirm-date', dayjs(value).add(1, 'day').format('YYYY/MM/DD'))
        emit('confirm-date', value);
      } else {
        emit('confirm-date', value);
      }
    };
    var disabledDateApi = function disabledDateApi(date) {
      if (props.disabledDate === 'disabledFuture') {
        if (dayjs(date).isBefore(dayjs())) return false;
        return true;
      } else {
        if (dayjs(date).isBefore(dayjs())) return true;
        return false;
      }
    };
    return {
      date: date,
      confirmDate: confirmDate,
      defaultDate: defaultDate,
      disabledDateApi: disabledDateApi
    };
  }
});