import HyRequest from './request';
import { proxyPrefix } from '@/utils';
import { TIME_OUT } from '@/config/common';

const {
  VUE_APP_PROXY_PREFIX_NAME_API,
  VUE_APP_PROXY_PREFIX_NAME_TABLEAPI,
  VUE_APP_PROXY_PREFIX_NAME_LTERPAPI,
  VUE_APP_PROXY_PREFIX_NAME_EASYBUYAPI
} = process.env;

export const hyRequest1 = new HyRequest({
  baseURL: proxyPrefix(VUE_APP_PROXY_PREFIX_NAME_LTERPAPI),
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      return config;
    },
    requestInterceptorsCatch: (error) => {
      return error;
    },
    responseInterceptors: (response) => {
      return response;
    },
    responseInterceptorsCatch: (error) => {
      return error;
    }
  }
});
export const hyRequest2 = new HyRequest({
  baseURL: proxyPrefix(VUE_APP_PROXY_PREFIX_NAME_TABLEAPI),
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      return config;
    },
    requestInterceptorsCatch: (error) => {
      return error;
    },
    responseInterceptors: (response) => {
      return response;
    },
    responseInterceptorsCatch: (error) => {
      return error;
    }
  }
});
export const hyRequest3 = new HyRequest({
  baseURL: proxyPrefix(VUE_APP_PROXY_PREFIX_NAME_TABLEAPI),
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      return config;
    },
    requestInterceptorsCatch: (error) => {
      return error;
    },
    responseInterceptors: (response) => {
      return response;
    },
    responseInterceptorsCatch: (error) => {
      return error;
    }
  }
});
export const hyRequest4 = new HyRequest({
  baseURL: proxyPrefix(VUE_APP_PROXY_PREFIX_NAME_EASYBUYAPI),
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      return config;
    },
    requestInterceptorsCatch: (error) => {
      return error;
    },
    responseInterceptors: (response) => {
      return response;
    },
    responseInterceptorsCatch: (error) => {
      return error;
    }
  }
});
export default new HyRequest({
  timeout: TIME_OUT,
  baseURL: proxyPrefix(VUE_APP_PROXY_PREFIX_NAME_API),
  interceptors: {
    requestInterceptors: (config) => {
      return config;
    },
    requestInterceptorsCatch: (error) => {
      return error;
    },
    responseInterceptors: (response) => {
      return response;
    },
    responseInterceptorsCatch: (error) => {
      return error;
    }
  }
});
