/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import weekYear from 'dayjs/plugin/weekYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(weekYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
export default defineComponent({
  name: 'CDateHeader',
  props: {
    type: {
      type: Number,
      default: 0
    },
    showDate: {
      type: Boolean,
      default: true
    },
    forceShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '报表周期'
    },
    disabledDate: {
      type: String,
      default: 'disabledFuture'
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    btnText: {
      type: String,
      default: '切换至表格'
    }
  },
  emits: ['switch', 'confirm-date'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var times = ref([new Date(), new Date()]);
    var date = ref(new Date());
    var confirmDate = function confirmDate(value) {
      if (props.type === 4) {
        var time = dayjs(times.value[0]).format('YYYY/MM/DD') + ',' + dayjs(times.value[1]).format('YYYY/MM/DD');
        // emit('confirm-date', dayjs(value).add(1, 'day').format('YYYY/MM/DD'))
        emit('confirm-date', time);
      } else {
        emit('confirm-date', formatDate(value));
      }
    };
    var switchBtnClick = function switchBtnClick() {
      emit('switch');
    };
    var disabledDateApi = function disabledDateApi(date) {
      if (props.disabledDate === 'disabledFuture') {
        if (dayjs(date).isSameOrBefore(dayjs())) return false;
        return true;
      } else {
        if (dayjs(date).isSameOrBefore(dayjs())) return true;
        return false;
      }
    };
    var disabledTimesBeforeApi = function disabledTimesBeforeApi(date) {
      if (dayjs(date).isSameOrBefore(dayjs(times.value[1]))) return false;
      return true;
    };
    var disabledTimesEndApi = function disabledTimesEndApi(date) {
      if (props.disabledDate === 'disabledFuture') {
        if (dayjs(date).isSameOrBefore(dayjs()) && dayjs(date).isSameOrAfter(dayjs(times.value[0]))) return false;
        return true;
      } else {
        if (dayjs(date).isSameOrAfter(dayjs(times.value[0]))) return false;
        return true;
      }
    };
    var reset = function reset() {
      date.value = new Date();
      times.value = [new Date(), new Date()];
    };
    return {
      switchBtnClick: switchBtnClick,
      date: date,
      times: times,
      reset: reset,
      confirmDate: confirmDate,
      disabledDateApi: disabledDateApi,
      disabledTimesEndApi: disabledTimesEndApi,
      disabledTimesBeforeApi: disabledTimesBeforeApi
    };
  }
});