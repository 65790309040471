export const API = {
  // 用户
  APPLOGIN_USERLOGIN: '/appLogin/userLogin',
  // 盘料
  PREFORM_PREFORMORDERREPORT: '/preform/preformOrderReport',
  PREFORM_PREFORMTREND: '/preform/preformTrend',
  PREFORM_PREFORMCONTRAST: '/preform/preformContrast',
  PREFORM_PREFORMSEARCHBYTIME: '/preform/preformSearchByTime',
  PREFORM_PREFORMREPORTTABLE: '/preform/preformReportTable'
};
