import type { App, Plugin } from 'vue';

function onInput(el: any, elInput: any, bindings: any, vnode: any) {
  console.log(vnode);
  return function handle() {
    // 只保留数字
    //获取input输入框的值
    let val = elInput.value;
    // modifiers为修饰符对象，传入了float，则其float属性为true
    //假如使用了.float修饰符，输入框可以输入带小数点的浮点数
    if (bindings.modifiers.float) {
      // 清除"数字"和"."以外的字符
      val = val.replace(/[^\d.]/g, '');
      // 只保留第一个, 清除多余的  ..   ...  ....等都清除  .保留
      val = val.replace(/\.{2,}/g, '.');
      // 第一个字符如果是.号，则补充前缀0
      val = val.replace(/^\./g, '0.');

      //假如指令有传值，如：v-number-input.float="2"
      if (typeof bindings.value !== 'undefined') {
        // 期望保留的最大小数位数
        let pointKeep = 0;
        if (
          typeof bindings.value === 'string' ||
          typeof bindings.value === 'number'
        ) {
          pointKeep = parseInt(bindings.value);
        }
        if (!isNaN(pointKeep)) {
          //如果pointkeep是有效数字
          if (!Number.isInteger(pointKeep) || pointKeep < 0) {
            //如果pointkeep不是整数或者是负数 设置pointkeep为0
            pointKeep = 0;
          }
          const str = '^(\\d+)\\.(\\d{' + pointKeep + '}).*$';
          const reg = new RegExp(str);
          if (pointKeep === 0) {
            // 不需要小数点
            val = val.replace(reg, '$1');
          } else {
            // 通过正则保留小数点后指定的位数
            val = val.replace(reg, '$1.$2');
          }
        }
      }
      if (Number(val) > 10000000) val = 10000000;
      elInput.value = val + '';
    } else {
      //假如没有使用.float修饰符,输入框只能输入整数  v-number-input 输入框所有非数字替换为空字符串
      elInput.value = elInput.value.replace(/[\D]/g, '');
    }
  };
}

const directiveNumberInput: Plugin = (app: App) => {
  app.directive('number-input', {
    mounted(el: any, bindings: any, vnode: any) {
      //获取input元素：假如指令绑定的元素不是input元素，获取其包含的input元素
      const elInput = el.tagName === 'INPUT' ? el : el.querySelector('input');

      //给input元素添加事件
      elInput.addEventListener(
        'keyup',
        onInput(el, elInput, bindings, vnode),
        false
      );
    }
  });
};
export default directiveNumberInput;
