import directiveIns from './ins';
import type { App, Plugin } from 'vue';
import registerVant from './register-vant';
import directiveNumberInput from './number-input';
import registerComponent from './register-component';
import registerProperties from './register-properties';

const plugins: Plugin[] = [
  registerVant,
  registerProperties,
  registerComponent,
  directiveIns,
  directiveNumberInput
];

export function globalRegister(app: App): void {
  for (const plugin of plugins) {
    app.use(plugin);
  }
}
