import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e3864e68"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "column"
};
var _hoisted_2 = {
  class: "info flex align-center justify-between"
};
var _hoisted_3 = {
  class: "name"
};
var _hoisted_4 = {
  class: "chart"
};
var _hoisted_5 = {
  class: "desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.name ? _ctx.item.name : '--'), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass({
      actualColumn: !_ctx.isWarn,
      warnColumn: _ctx.isWarn
    }),
    style: _normalizeStyle({
      width: _ctx.item.ratio + '%'
    })
  }, null, 6)]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.stone_value) + "米/" + _toDisplayString(_ctx.item.weight) + "吨", 1)])]);
}