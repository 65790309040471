/* unplugin-vue-components disabled */export default defineComponent({
  name: 'CTitle',
  emits: ['click'],
  props: {
    title: {
      type: String,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showJump: {
      type: Boolean,
      default: false
    },
    jumpText: {
      type: String,
      default: '查看设备'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var jumpTo = function jumpTo() {
      emit('click', props.title);
    };
    return {
      jumpTo: jumpTo
    };
  }
});