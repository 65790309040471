import hyRequest from '@/service';
import { API } from '@/config/api';
import { UserLoginData } from '@/interface/api/user';

export function userLogin() {
  const { VUE_APP_USERCODE, VUE_APP_USER_PASSWORD } = process.env;
  return hyRequest.post<UserLoginData>({
    url: API.APPLOGIN_USERLOGIN,
    data: {
      psd: VUE_APP_USER_PASSWORD,
      userCode: VUE_APP_USERCODE
    }
  });
}
